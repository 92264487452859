<template>
	<div>
		<div class="generic-title">Our Blog</div>
		<div class="blog-grid-wrapper">
			<div class="blog-grid">
				<div v-for="article in articles" :key="`blog-${ article.id }`">
					<router-link :to="`/blog/${ article.slug }`">
						<img v-if="article.photo_filename" :src="`${article.photo_filepath}250.${article.photo_filename}`" :alt="article.title">
						<div class="name">{{ article.title }}</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {

	name: 'BlogPage',

	async asyncData({ app }) {
		const { data } = await app.$api.get('/blog');

		return {
			articles: data.data,
		};
	},

	data() {
		return {
			articles: [],
		};
	},

};

</script>
